.column {
  background: #e5e5e5 !important;
}

.column::before {
  display: none;
}

.column > div > button {
  background: #374151 !important;
  color: #fff !important;
  border: 1px solid #374151 !important;
}

.fileIcon {
  width: 40px;
  height: 40px;
  margin-bottom: 30px;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.ant-pro-card {
  padding-block: 0;
}
