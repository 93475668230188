@tailwind base;
@tailwind components;
@tailwind utilities;
@switch-bg: red;

* {
  outline: none;
}

#webpack-dev-server-client-overlay {
  display: none;
}

:root,
[data-theme] {
  background: rgb(243 244 246);
}

.ant-table
  .ant-table-middle
  .ant-table-fixed-header
  .ant-table-fixed-column
  .ant-table-scroll-horizontal
  .ant-table-has-fix-left {
  margin: 0 !important;
}

.ant-switch.ant-switch-checked {
  background: #1677ff !important;
}

.ant-switch:hover:not(.ant-switch-disabled) {
  background: rgba(0, 0, 0, 0.45);
}

.ant-switch {
  background: rgba(0, 0, 0, 0.25);
}

.table-row-green {
  background-color: green !important;
}

.positionsTable .ant-pro-card > .ant-pro-card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.cartModalTable .ant-pro-card > .ant-pro-card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.orderTable .ant-pro-card > .ant-pro-card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-pro-table-list-toolbar-left {
  max-width: unset !important;
  flex-grow: 1 !important;
  width: calc(100% - 50px) !important;
  min-width: calc(100% - 50px) !important;
}

.ant-pro-table-list-toolbar-title {
  justify-content: space-between !important;
  min-width: 100% !important;
}

.ant-btn-primary:not(:disabled) {
  background-color: #1677ff !important;
}

.ant-btn-dangerous:not(:disabled) {
  background-color: #ff4d4f !important;
}

.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
  border-bottom: 0px solid #f0f0f0 !important;
  border-width: 0px !important;
}
